@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Red+Hat+Display&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &:focus {
    box-shadow: unset !important;
  }
}
body::-webkit-scrollbar{
  display: none;
  width: 0 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

iframe {
  display: none !important;
}

#team-items .slick-list {
  overflow-y: visible !important;
  overflow-x: hidden !important;
  height: 320px;

  @media (min-width: 640px) {
    height: 330px;
  }

  @media (min-width: 768px) {
    height: 420px;
  }
}

#mobile-opp {
  .slick-list {
    text-align: center !important;
  }

  @media (max-width: 1024px) {
    display: block !important;
  }

  @media (min-width: 1025px) {
    display: none !important;
  }
}

#bg-screen {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.lng-dropdown {

  &__container {
    border: 1px solid #DFE0E2;
    background-color: #fff;
    width: 78px;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 5px;
    cursor: pointer;

  }

  &__inner {
    position: absolute;
    border: 1px solid #DFE0E2;
    background-color: #fff;
    z-index: 100;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    border-radius: 5px;
    top: 120%;


    &.visible {
      display: block;
      top: 120%;
      animation: showDropdown .2s ease-in;
      animation-fill-mode: forwards;
    }

    &.hidden {
      display: none;
    }
  }


  &__trigger {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--label {
      flex: 1;


    }

  }

  &__item {
    cursor: pointer;
    display: inline-block;
    padding: 2px 8px;
    width: 100%;

    &:hover {
      background-color: darken($color: #fff, $amount: 2);
    }
  }


}

.gradient-border {
  box-shadow: 0px 8px 18px 0px rgba(189, 189, 189, 0.2);
  background: linear-gradient(45deg, #FFFFFF, #F5F5FF, #EBEBFF);
  border: 0.5px solid #EBEBFF;

}

@keyframes showDropdown {
  0% {
    top: 80%;
  }

  100% {
    top: 120%;
  }
}

.left {
  transform: translateX(0%);
}

.mid {
  transform: translateX(100%);
}

.right {
  transform: translateX(200%);
}

.toggle-btn {
  transition: transform 200ms ease-out;
}

