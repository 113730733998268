*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*:focus {
    box-shadow: none !important;
    outline: 0 !important;
}


*[data-focus] {
    box-shadow: none !important;
}
iframe{
    display: block !important;
    height: calc(100% - 24px) !important;}

body {
    font-family: 'DM Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auto-complete-container {
    position: relative;
}


.location-item,
.location-item__active {
    padding: 5px 20px !important;
    box-sizing: border-box !important;
    display: block !important;
    width: 100%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #3B3D40;
}

.location-item:hover {
    background-color: #eee !important;
}

.location-item__active:hover {
    background-color: #eee !important;
}

.auto-complete-loading {
    padding: 20px;
}

.location {
    display: inline-block !important;
    width: 100% !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    display: inline-block !important;
    overflow: hidden !important;
}


.auto-complete-dropdown-container {
    position: relative;
    top: 110px;
    min-height: 150px;
    max-height: 200px;
    border: 1.4px solid #E9EAEC;
    border-radius: 10px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    position: absolute;
    z-index: 40;
    background-color: #fff;
    width: 100%;


}

.dropdown {
    border-radius: 10px !important;

}

.dropdown__control {
    border: 1.4px solid #E9EAEC !important
}

.dropdown__arrow {
    top: 50% !important;
    transform: translateY(-50%);
}

.dropdown__placeholder {
    color: #3B3D40;
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 150px;
}


.Dropdown-control {
    height: 56px;
    border-radius: 10px !important;
}

.dropdown__menu {
    border-radius: 10px !important;
    margin-top: 12px !important;
    overflow-x: hidden;
    border: 1.4px solid #E9EAEC !important;
    padding: 20px;
}


.dropdown__menu::-webkit-scrollbar {
    width: 20px;

}

.dropdown__menu::-webkit-scrollbar-track {
    border-radius: 1px;
    box-shadow: inset 0 0 10px 10px #F7F7F8;
    border: solid 5px transparent;

}

.dropdown__menu::-webkit-scrollbar-thumb {
    border-radius: 1px;
    box-shadow: inset 0 0 10px 10px #E4E5E7;
    border: solid 5px transparent;
}

.chakra-button {
    overflow: hidden;
}


input[type="checkbox"] {
    width: var(--chakra-sizes-5);
    height: var(--chakra-sizes-5);
    appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
    border: 2px solid;
    border-color: rgb(226, 232, 240);
    cursor: pointer;
    text-align: center;
    border-radius: var(--chakra-radii-sm);
    position: relative;
}

input[type="checkbox"]::after {
    box-sizing: border-box;
    content: '\2713';
    font-family: inherit;
    display: none;
    position: absolute;
    width: var(--chakra-sizes-5);
    height: var(--chakra-sizes-5);
    border-radius: var(--chakra-radii-sm);
    cursor: pointer;
    color: #fff;
    border: 2px solid;
    background: var(--chakra-colors-orange-500);
    border-color: var(--chakra-colors-orange-500);
    left: -2px;
    top: -2px;

}

input[type="checkbox"]:checked::after {
    display: flex;
    align-items: center;
    justify-content: center;

}

@keyframes rotateImage {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotate-img {
   animation: rotateImage 10s linear infinite;
}
input:focus~label,
input:not(:placeholder-shown) ~ label
{
    top: 5px;
    font-size: 12px;
}


#chakra-modal-mymodal {
    max-width:1000px;
}
.chakra-alert{
    background-color: #E36E1E !important;
}
